import tw, { styled } from "twin.macro";
import type { TOrientation } from "@/types/common";

const styles = {
  wrapperCity: {
    root: tw`flex items-center gap-xs border border-neutral-90 transition-all duration-300 ease-in-out cursor-pointer rounded-l relative w-max 
    hover:(bg-neutral-99 border-neutral-80) `,
    orientation: {
      horizontal: tw`p-s medium:(gap-m) `,
      vertical: tw`px-s py-xxs medium:(gap-sm)`,
    },
    svg: tw`flex flex-col justify-center items-center w-6 h-[1.8rem] `,
  },

  wrapperTextVertical: tw`flex gap-xxs medium:(flex-col items-start w-full) flex-col items-start`,
};
export const StyledWrapperTextVertical = styled.div`
  ${styles.wrapperTextVertical}
`;

export const StyledWrapperCiity = styled.button<{
  $orientation?: TOrientation;
  $fullWidth?: boolean;
  $removeBoxStyle: boolean;
}>`
  ${styles.wrapperCity.root}

  ${({ $orientation }) => $orientation && styles.wrapperCity.orientation[$orientation]}
  ${({ $fullWidth }) => {
    if ($fullWidth) {
      return tw`w-full`;
    }
  }}

  & > svg {
    ${styles.wrapperCity.svg}
  }

  ${({ $removeBoxStyle }) => $removeBoxStyle && tw`border-none p-0`}
`;
