import { useState } from "react";
import tw, { theme } from "twin.macro";
import Popover, { PopoverContent, PopoverTrigger } from "@atoms/Popover";
import BrandLogoFithub from "@/components/Atoms/BrandLogoFithub";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import HamburguerButton from "@/components/Atoms/HamburgerButton";
import { Link } from "react-router-dom";
import Avatar from "@/components/Atoms/Avatar";

import useAppSelector from "@/hooks/useAppSelector";
import IconChevronRight from "@iconsV2/Arrow/chevron-right-normal.svg?react";
import ButtonText from "@/components/Atoms/ButtonText";
import Ciity from "@/components/Atoms/Ciity";
import ShoppingCart from "@/components/Organisms/ShoppingCart";
import Button from "@/components/Atoms/Button";
import IconUser from "@iconsV2/Users/user-circle.svg?react";
import useModalAuth from "@/hooks/useModalAuth";
import useAddressModal from "@/hooks/useAddressModal";
import ProfileMenu from "../ProfileMenu";
import Heading from "@/components/Atoms/Heading";
import CategoryButton from "@/components/Atoms/CategoryButton";
import IconHeartOutline from "@iconsV2/Controllers/heart-outline.svg?react";
import { StyledMenuSectionContainer } from "./styles";
import IconLabelTag from "@iconsV2/Interrface/label-tag-01.svg?react";
import IconBike from "@iconsV2/Interrface/bike.svg?react";
interface IHeaderMenu {
  open: boolean;
  handleOpen: (value: boolean) => void;
  isScrolled: boolean;
}

const MENU_ITEMS = {
  topCategories: [
    {
      label: "Promofit",
      title: "Promofit",
      to: "/c/promofit",
      endIcon: <IconLabelTag />,
      promoted: true,
    },
    {
      title: "Nuestra marca",
      label: "Nuestra marca",
      to: "/c/nuestra-marca",
      endIcon: <IconChevronRight />,
      promoted: false,
    },
    {
      title: "Nuevos",
      label: "Nuevos",
      to: "/c/nuevos",
      endIcon: <IconChevronRight />,
      promoted: false,
    },
  ],

  resources: [
    {
      label: "Comunidad",
      title: "Comunidad de Fithub",
      to: "",
      endIcon: <IconChevronRight />,
    },
    {
      label: "Blog",
      title: "Blog de Fithub",
      to: "https://blog.fithub.com.co",
      endIcon: <IconChevronRight />,
      target: "_blank",
      rel: "noopener noreferrer",
    },
    {
      label: "Canjea tu regalo",
      title: "Canjea tu regalo",
      to: "/gift-cards/redeem",
      endIcon: <IconChevronRight />,
    },
  ],

  userOptions: [
    {
      label: "Favoritos",
      title: "Favoritos",
      to: "",
      endIcon: <IconHeartOutline />,
    },
    {
      label: "Tus pedidos",
      title: "Tus pedidos",
      to: "/orders",
      endIcon: <IconBike />,
    },
  ],
};
const HeaderMenu = ({ open, handleOpen, isScrolled }: IHeaderMenu) => {
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const isLarge = useMediaQuery("( min-width: {large} )");

  const { handleOpenModalAuth } = useModalAuth();
  const { isOpen: isAddressModalOpen, toggleAddressModal } = useAddressModal();

  const user = useAppSelector((state) => state.user);
  const nameUser = `${user?.firstName} ${user?.lastName}`;

  const handleOpenProfileMenu = (open: boolean) => {
    setOpenProfileMenu(open);
  };

  const handleToggleOpen = () => {
    handleOpen(!open);
  };

  return (
    <Popover
      placement="bottom-start"
      onOpenChange={handleToggleOpen}
      open={open}
      hidden={false}
      fullScreen={!isMedium}
    >
      <PopoverTrigger>
        <HamburguerButton open={open} handleOpen={handleToggleOpen} />
      </PopoverTrigger>

      <PopoverContent
        classes={{
          root: tw`flex flex-col z-[52] w-full bg-secondary-100 !p-0 all-child:!w-full medium:(w-[346px]) large:(w-[396px])`,
        }}
      >
        <ProfileMenu
          nameUser={nameUser}
          openMenu={openProfileMenu}
          handleOpenMenu={handleOpenProfileMenu}
          placement="right-start"
          onCloseLogout={handleToggleOpen}
        >
          <div tw="flex flex-col gap-m mt-s p-m">
            {!isMedium && (
              <div tw="w-full flex justify-between items-center">
                <div tw="flex items-center gap-s">
                  <HamburguerButton open handleOpen={handleToggleOpen} />

                  <Link to="/" title="Fithub" onClick={handleToggleOpen}>
                    <BrandLogoFithub tw="w-[4.625rem] h-[1.5rem]" />
                  </Link>
                </div>

                <ShoppingCart isScrolled={isScrolled} />
              </div>
            )}

            {(isLarge || (isMedium && isScrolled)) && (
              <Link to="/" title="Fithub" onClick={handleToggleOpen}>
                <BrandLogoFithub tw="w-[5.375rem] h-[1.5rem]" />
              </Link>
            )}

            {user?.firstName ? (
              <>
                <button
                  tw="w-full bg-neutral flex items-center justify-between gap-xxs rounded-s py-sm px-m large:(py-m)"
                  onClick={() => handleOpenProfileMenu(true)}
                >
                  <div tw="flex items-center gap-sm">
                    <Avatar
                      size="large"
                      label={nameUser}
                      backgroundColor={theme`colors.secondary.100`}
                      color={theme`colors.neutral.10`}
                    />

                    <ButtonText tw="text-left" sizeVariant="md" color={theme`colors.secondary.100`}>
                      {user?.firstName} {user?.lastName}
                    </ButtonText>
                  </div>

                  <IconChevronRight tw="w-ml h-ml all-child:fill-secondary-100" />
                </button>

                <StyledMenuSectionContainer>
                  {MENU_ITEMS.userOptions.map((item, index) => {
                    return (
                      <CategoryButton
                        key={index}
                        as={Link}
                        label={item.label}
                        title={item.title}
                        color="secondary"
                        to={item.to}
                        sizeVariant="md"
                        onClick={handleToggleOpen}
                        endIcon={item.endIcon}
                        className="header-menu-item"
                      />
                    );
                  })}
                </StyledMenuSectionContainer>
              </>
            ) : (
              <Button
                sizeVariant="lg"
                color="secondary"
                variant="outlined"
                onClick={() => {
                  handleOpenModalAuth({ initialStep: "login" });
                  handleToggleOpen();
                }}
                startIcon={<IconUser tw="fill-secondary-50" />}
                justify="flex-start"
                tw="rounded-l"
              >
                Iniciar sesión
              </Button>
            )}

            {/* Categories most popular */}
            <hr />
            <StyledMenuSectionContainer>
              <Heading type="h6">Novedades y promociones</Heading>

              {MENU_ITEMS.topCategories.map((item, index) => {
                return (
                  <CategoryButton
                    key={index}
                    as={Link}
                    label={item.label}
                    title={item.title}
                    color="secondary"
                    to={item.to}
                    sizeVariant="md"
                    onClick={handleToggleOpen}
                    endIcon={item.endIcon}
                    className={`header-menu-item ${item.promoted && "header-menu-item__promo"}`}
                  />
                );
              })}
            </StyledMenuSectionContainer>

            <hr />

            <StyledMenuSectionContainer>
              {MENU_ITEMS.resources.map((item, index) => {
                return (
                  <CategoryButton
                    key={index}
                    color="secondary"
                    sizeVariant="md"
                    as={Link}
                    label={item.label}
                    title={item.title}
                    to={item.to}
                    target={item.target}
                    rel={item.rel}
                    onClick={handleToggleOpen}
                    endIcon={item.endIcon}
                    className="header-menu-item"
                  />
                );
              })}
            </StyledMenuSectionContainer>

            <hr />

            <div tw=" all-child:w-full" onClick={handleToggleOpen}>
              <Ciity
                open={isAddressModalOpen}
                handleChangeOpen={toggleAddressModal}
                withPopover={false}
                textDirection="horizontal"
                fullWidth
              />
            </div>
          </div>
        </ProfileMenu>
      </PopoverContent>
    </Popover>
  );
};

export default HeaderMenu;
