import tw, { styled } from "twin.macro";
import IconChevromDown from "@iconsV2/Arrow/chevron-bottom-normal.svg?react";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import type { TOrientation, TSize } from "@/types/common";

interface StyledCategoryListProps {
  $orientation: TOrientation;
}
const styles = {
  iconChevronDown: {
    base: tw`transition-transform duration-300 ease-in-out transform min-[750px]:(w-5 h-5)`,
    size: {
      small: tw`w-[1.125rem] h-[1.125rem]`,
      large: tw`w-6 h-6`,
    },
  },
  categoryList: {
    base: tw`grid medium:(grid-cols-2 px-0) all-child:(p-0 hover:(bg-transparent underline text-secondary-60))`,
    orientation: {
      horizontal: tw`gap-x-m gap-y-ml medium:(grid-cols-2)`,
      vertical: tw`gap-ml medium:(flex flex-col)`,
    },
  },
  containerContent: tw`flex flex-col gap-l p-m medium:(flex-row p-0)`,
  section: tw`flex flex-col gap-m pb-ml border-b border-neutral-90 last:border-b-0 medium:(pb-0 border-0)`,

  searchWrapper: {
    base: tw`flex gap-m px-m my-s`,
    searchBar: tw`w-full`,
  },

  itemActive: tw`underline text-secondary-60`,
};

export const StyledIconChevronDown = styled(IconChevromDown)<{ $size?: TSize; $open: boolean }>`
  ${styles.iconChevronDown.base}

  ${({ $size }) => {
    return applyResponsiveStyles({ stylesBreakpoints: styles.iconChevronDown.size, size: $size });
  }}
  ${({ $open }) => $open && tw`rotate-180`}
  & path {
    ${tw`fill-current`}
  }
`;

export const StyledContainerContent = styled.div`
  ${styles.containerContent}

  .list-item-active {
    ${styles.itemActive}
  }
`;

export const StyledSection = styled.section`
  ${styles.section}
`;
export const StyledCategoryList = styled.div<StyledCategoryListProps>`
  ${styles.categoryList.base}
  ${({ $orientation }) => $orientation && styles.categoryList.orientation[$orientation]}
`;

export const StyledSearchWrapper = styled.div`
  ${styles.searchWrapper.base}

  div:nth-of-type(1) {
    ${styles.searchWrapper.searchBar}
  }
`;
