import tw, { styled } from "twin.macro";

import IconChevromDown from "@iconsV2/Arrow/chevron-bottom-normal.svg?react";
import type { TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";

const styles = {
  root: tw`flex bg-secondary-100 items-stretch h-[2.05rem] w-full transition-all medium:(h-[2.25rem]) large:(max-w-[37.5rem])`,
  wrapperInput: {
    base: tw`flex w-full border rounded-lg min-[750px]:(border-l) h-full overflow-hidden transition-[border-color] duration-300 ease-in-out`,
    svg: {
      base: tw`self-center [& path]:(fill-neutral-70) transition-all duration-150 ease-in-out max-w-0 overflow-hidden`,
      size: {
        small: tw`w-[1rem] h-[1rem]`,
        medium: tw`w-[1.25rem] h-[1.25rem]`,
        large: tw`w-[1.5rem] h-[1.5rem]`,
      },
    },
  },
  contentInput: {
    base: tw`w-full h-full flex flex-1 items-center cursor-text min-[750px]:ml-sm`,
    size: {
      small: tw`ml-s`,
      large: tw`ml-m`,
    },
  },
  input: {
    base: tw`bg-transparent text-neutral-0 border-none min-[750px]:(text-[0.75rem] tracking-[0.0075rem] )`,
    size: {
      small: tw`text-[0.625rem] tracking-[0.00625rem]`,
      large: tw`text-[0.875rem] tracking-normal`,
    },
  },
  iconChevronDown: {
    base: tw`transition-transform duration-300 ease-in-out transform min-[750px]:(w-5 h-5)`,
    size: {
      small: tw`w-[1.125rem] h-[1.125rem]`,
      large: tw`w-6 h-6`,
    },
  },
};

export const StyledWrapperInputSearch = styled.div<{ $isScrolled: boolean }>`
  ${styles.root}

  ${({ $isScrolled }) => {
    if ($isScrolled) {
      return tw`medium:(h-[2.25rem]) `;
    }
  }}
`;

export const StyledContentInput = styled.div<{ $size?: TSize }>`
  ${styles.contentInput.base}
  ${({ $size }) => applyResponsiveStyles({ stylesBreakpoints: styles.contentInput.size, size: $size })}
`;

export const StyledInput = styled.input<{ $size?: TSize }>`
  ${styles.input.base}
  ${tw`font-bold placeholder:font-normal w-full`}
  ${({ $size }) => applyResponsiveStyles({ stylesBreakpoints: styles.input.size, size: $size })}
`;

export const StyledSuggestion = styled.div<{ $size?: TSize }>`
  ${styles.input.base}
  ${tw`line-clamp-1`}
  ${({ $size }) => applyResponsiveStyles({ stylesBreakpoints: styles.input.size, size: $size })}
`;

export const StyledWrapperInput = styled.div<{ $size?: TSize }>`
  ${styles.wrapperInput.base}
  ${tw`border-neutral-85`}

  & > svg {
    ${styles.wrapperInput.svg.base}

    ${({ $size }) => applyResponsiveStyles({ stylesBreakpoints: styles.wrapperInput.svg.size, size: $size })}
  }

  &:hover {
    ${tw`border-neutral-60`}
  }

  & > svg:has(+ ${StyledContentInput} > ${StyledInput}:focus) {
    ${tw`block ml-m max-w-[1.5rem]`}
  }

  &:has(> ${StyledInput}:focus) {
    ${tw`border-neutral-10`}
  }
`;

export const StyledWrapperPopoverButtons = styled.div`
  ${tw`grid grid-cols-2 gap-x-m gap-y-xs`}
  .category-list-item {
    ${tw` hover:bg-neutral-90 p-s large:(p-sm)`}
  }

  .category-list-item-active {
    ${tw`bg-neutral-90`}
  }
`;

export const StyledIconChevronDown = styled(IconChevromDown)<{ $size?: TSize; $open: boolean }>`
  ${styles.iconChevronDown.base}

  ${({ $size }) => applyResponsiveStyles({ stylesBreakpoints: styles.iconChevronDown.size, size: $size })}
  ${({ $open }) => $open && tw`rotate-180`}
  & path {
    ${tw`fill-current`}
  }
`;

export const StyledInputMobile = styled.div`
  ${tw`w-full rounded-round min-h-[2.05rem] h-[2.05rem] bg-secondary-100 medium:(h-[44px]) large:(h-xxl)`}

  & > div {
    ${tw`rounded-s py-0 h-full flex items-center gap-xxs`}
  }
`;

// ${tw`flex flex-col items-start gap-1 large:(gap-1.5)`}
