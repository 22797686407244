import { combineReducers, configureStore, getDefaultMiddleware, type Middleware } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform,
  type PersistConfig,
} from "redux-persist";
import { userSlice } from "./user";
import { cartStoresSlice } from "./cartStore";
import storage from "redux-persist/lib/storage";
import { confirmationModalSlice } from "./confirmationModal";
import { genericsSlice } from "./generics";
import { type TypedUseSelectorHook, useSelector } from "react-redux";
import { PopUpsReminderSlice } from "./PopUpsReminderSlice";
import { newCartToOldCart } from "@/utils/product";

const rootReducer = combineReducers({
  popUpsReminder: PopUpsReminderSlice.reducer,
  user: userSlice.reducer,
  cart: cartStoresSlice.reducer,
  confirmationModal: confirmationModalSlice.reducer,
  [genericsSlice.name]: genericsSlice.reducer,
});

type RootState = ReturnType<typeof rootReducer>;

const ignoreReducers = ["confirmationModal", genericsSlice.name];

const omitSlicesTransform = createTransform(
  (inboundState: unknown, key) => {
    if (ignoreReducers.includes(key as keyof typeof rootReducer)) {
      return undefined;
    }
    return inboundState;
  },
  (outboundState) => {
    return outboundState;
  },
  { whitelist: ignoreReducers }
);

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  version: 1,
  storage,
  transforms: [omitSlicesTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Middleware to format the cart from v2 to v1
const formatFromV2ToV1: Middleware = (storeAPI) => (next) => (action) => {
  if (action.type === REHYDRATE) {
    const incomingState = action.payload;
    if (incomingState?.cart) {
      // Validate if the config is v1 and the cart is in v2 format
      if (
        persistConfig.version === 1 &&
        incomingState.cart.storeId !== undefined &&
        incomingState.cart?.items?.length > 0 &&
        incomingState.cart?.items?.some((item) => item.product?.id !== undefined)
      ) {
        incomingState.cart.items = incomingState.cart.items.map((item: NewCart) => {
          return newCartToOldCart(item);
        });
      }
    }
  }
  return next(action);
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, "generics/setOpenModalAuth"],
    },
  }).concat(formatFromV2ToV1),
});

const persistor = persistStore(store);

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;

export { store, persistor, useTypedSelector };
