import { type CSSProperties, useState, type ReactNode, useEffect, useRef } from "react";
import { theme, type TwStyle } from "twin.macro";
import { type RuleSet } from "styled-components";
import AddIcon from "@iconsV2/Edit/add.svg?react";
import RemoveIcon from "@iconsV2/Edit/remove.svg?react";
import useResizeObserver from "@/hooks/useResizeObserver";
import {
  StyledCard,
  StyledContent,
  StyledContentClose,
  StyledContentPadding,
  StyledDetails,
  StyledHeading,
  StyledSummary,
} from "./styles";
import Heading from "../Heading";
import Paragraph from "../Paragraph";

export interface ICardDismissible {
  title?: string | ReactNode;
  collapsedValue?: string;
  children?: ReactNode;
  defaultOpen?: boolean;
  contentClose?: ReactNode;
  withShadow?: boolean;
  backgroundColor?: CSSProperties["backgroundColor"];
  classes?: {
    root?: RuleSet<object> | TwStyle;
    head?: RuleSet<object> | TwStyle;
  };
  onChange?: (expanded: boolean) => void;
  active?: boolean;
  open?: boolean;
  activeHover?: boolean;
  activeColor?: CSSProperties["borderColor"];
  key?: any;
}

const CardDismissible = ({
  title,
  collapsedValue,
  classes,
  children,
  open,
  defaultOpen,
  contentClose,
  withShadow = false,
  backgroundColor,
  onChange,
  active = false,
  activeHover = false,
  activeColor = theme`colors.neutral.60`,
}: ICardDismissible) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(open ?? defaultOpen ?? false);
  const [maxHeight, setMaxHeight] = useState(0); // Max height for the animation
  const isExpanded = open ?? expanded;

  const handelExpanded = (e: React.SyntheticEvent<HTMLDetailsElement, Event>) => {
    const newValue = e.currentTarget.open;
    setExpanded(newValue);
    onChange?.(newValue);
  };

  // Listen for changes external of open prop
  useEffect(() => {
    if (open !== undefined) {
      setExpanded(open);
      onChange?.(open);
    }
  }, [open]);

  // Set the max height when the content height changes
  useResizeObserver({
    ref: contentRef,
    callback: () => {
      const newHeight = contentRef.current?.offsetHeight ?? 0;
      setMaxHeight(newHeight);
    },
  });

  return (
    <StyledCard
      activeColor={activeColor}
      classes={classes}
      withShadow={withShadow}
      active={active && isExpanded}
      activeHover={activeHover}
      $backgroundColor={backgroundColor}
    >
      <StyledDetails open={isExpanded} onToggle={handelExpanded} $maxHeight={maxHeight}>
        <StyledSummary>
          <span tw="w-full">
            <StyledHeading $classes={classes?.head} $headingWithBorder={isExpanded}>
              {typeof title === "string" ? <Heading type="h6">{title}</Heading> : title}

              <div tw="flex items-center gap-s">
                {collapsedValue && collapsedValue.length > 0 && !isExpanded && (
                  <Paragraph sizeVariant="sm" tw="max-w-[6.25rem] line-clamp-1">
                    {collapsedValue}
                  </Paragraph>
                )}

                {isExpanded ? <RemoveIcon /> : <AddIcon />}
              </div>
            </StyledHeading>
          </span>
        </StyledSummary>
      </StyledDetails>

      <StyledContent id="card-dismissable-content">
        <StyledContentPadding ref={contentRef}>{children}</StyledContentPadding>
      </StyledContent>

      {contentClose && !isExpanded && <StyledContentClose>{contentClose}</StyledContentClose>}
    </StyledCard>
  );
};

export default CardDismissible;
