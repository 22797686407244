import { type CSSProperties } from "styled-components";
import tw, { css, styled } from "twin.macro";

interface StyledBottomNotificationHeaderProps {
  $backgroundColor: CSSProperties["backgroundColor"];
}
const styles = {
  root: tw`flex items-center justify-center py-xxs bg-transparent transition-["background","color"] duration-1000 large:(py-s)`,
};

export const StyledBottomNotificationHeader = styled.div<StyledBottomNotificationHeaderProps>`
  ${styles.root}

  ${({ $backgroundColor }) => {
    if ($backgroundColor) {
      return css`
        background: ${$backgroundColor};
      `;
    }
  }}
`;
