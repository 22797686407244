import { type ButtonHTMLAttributes, type FC, type ReactNode, type PropsWithRef, forwardRef } from "react";
import { type TwStyle } from "twin.macro";
import type { CSSProperties, RuleSet } from "styled-components";
import {
  StyledButtonText,
  StyledEndIcon,
  StyledStartIcon,
  StyledWrapperBtnCategory,
  StyledWrapperContent,
} from "./styles";
import type { ITypographyCommon, TColor, TSizeVariant } from "@/types/common";

export interface ICategoryButton
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    Omit<ITypographyCommon, "sizeVariant"> {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  color?: TColor;
  rounded?: boolean;
  label?: string;
  justify?: CSSProperties["justifyContent"];
  active?: boolean;
  classes?: {
    root?: RuleSet<object> | TwStyle;
  };
  [key: string]: any;
  sizeVariant?: TSizeVariant;
  fontWeight?: CSSProperties["fontWeight"];
}

const CategoryButton: FC<PropsWithRef<ICategoryButton>> = forwardRef(
  (
    {
      children,
      size,
      startIcon,
      endIcon,
      label,
      color = "primary",
      fullWidth = false,
      rounded = true,
      justify = "start",
      active = false,
      classes,
      sizeVariant = "md",
      fontWeight,
      ...props
    },
    ref
  ) => {
    return (
      <StyledWrapperBtnCategory
        ref={ref}
        {...props}
        $size={size}
        $color={color}
        $fullWidth={fullWidth}
        $rounded={rounded}
        $active={active}
        $classes={classes?.root}
      >
        {startIcon && <StyledStartIcon $size={size}>{startIcon}</StyledStartIcon>}

        <StyledWrapperContent $justify={justify}>
          {label ? (
            <StyledButtonText size={size} sizeVariant={sizeVariant} fontWeight={fontWeight}>
              {label}
            </StyledButtonText>
          ) : (
            <>{children}</>
          )}
        </StyledWrapperContent>

        {endIcon && <StyledEndIcon $size={size}>{endIcon}</StyledEndIcon>}
      </StyledWrapperBtnCategory>
    );
  }
);

export default CategoryButton;
