import tw, { styled } from "twin.macro";
import IconChevromDown from "@iconsV2/Arrow/chevron-bottom-normal.svg?react";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import { type TSize } from "@/types/common";

const styles = {
  iconChevronDown: {
    base: tw`transition-transform duration-300 ease-in-out transform min-[750px]:(w-5 h-5)`,
    size: {
      small: tw`w-[1.125rem] h-[1.125rem]`,
      large: tw`w-6 h-6`,
    },
  },
};

export const StyledIconChevronDown = styled(IconChevromDown)<{ $size?: TSize; $open: boolean }>`
  ${styles.iconChevronDown.base}

  ${({ $size }) => {
    return applyResponsiveStyles({ stylesBreakpoints: styles.iconChevronDown.size, size: $size });
  }}
  ${({ $open }) => $open && tw`rotate-180`}
  & path {
    ${tw`fill-current`}
  }
`;

export const StyledWrapperPopoverButtons = styled.div`
  ${tw`grid px-m pb-ml gap-x-m gap-y-ml medium:(pb-0 grid-cols-2 px-0 ) p-0 all-child:(hover:(bg-transparent underline text-secondary-60) p-0 )`}

  .list-item-active {
    ${tw`underline text-secondary-60`}
  }
`;
