import tw, { styled } from "twin.macro";

interface StyledContainerIconOrderProps {
  $isScrolled: boolean;
}
const styles = {
  container: {
    base: tw`relative flex justify-center items-center border border-secondary-50 rounded-full`,
    notScrolled: tw`w-[1.875rem] h-[1.875rem] medium:(w-[1.25rem] h-[1.25rem]) large:(w-6 h-6)`,
    isScrolled: tw`w-[1.875rem] h-[1.875rem] medium:(w-8 h-8) large:(w-9 h-9) `,
  },

  svg: {
    notScrolled: tw`(w-[1.125rem] h-[1.125rem]) medium:(w-[0.938rem] h-[0.938rem]) large:(w-5 h-5)`,
    isScrolled: tw`w-[1.125rem] h-[1.125rem] medium:(w-5 h-5) `,
  },

  notification: {
    notScrolled: tw`top-0 right-0 medium:(-top-[0.188rem] -right-[0.25rem])`,
    isScrolled: tw`top-0 right-0  `,
  },
};

export const StyledContainerIconOrder = styled.div<StyledContainerIconOrderProps>`
  ${styles.container.base}

  ${({ $isScrolled }) => {
    if ($isScrolled) {
      return styles.container.isScrolled;
    } else {
      return styles.container.notScrolled;
    }
  }}
  svg {
    ${({ $isScrolled }) => {
      if ($isScrolled) {
        return styles.svg.isScrolled;
      } else {
        return styles.svg.notScrolled;
      }
    }}
  }
`;

export const StyledNotification = styled.div<StyledContainerIconOrderProps>`
  ${tw`absolute bg-error-50 w-s h-s rounded-full`}
  ${({ $isScrolled }) => {
    if ($isScrolled) {
      return styles.notification.isScrolled;
    } else {
      return styles.notification.notScrolled;
    }
  }}
`;
