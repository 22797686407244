import TextField, { type ITextFieldProps } from "@/components/Atoms/TextField";
import { StyledSearchBarContent, StyledSearchBarRoot } from "./styles";
import IconSearch from "@/components/Icons/V2/Interrface/magnifier-search-01.svg?react";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import Button from "@/components/Atoms/Button";
import Label from "@/components/Atoms/Label";
import { useId } from "react";

interface SearchBarProps {
  inputProps?: Omit<ITextFieldProps, "startIcon" | "endIcon">;
  onClick?: () => void;
  cleanInput?: () => void;
  id?: string;
  label?: string;
}
export const SearchBar = ({ inputProps, onClick, cleanInput, id, label }: SearchBarProps) => {
  const generateId = useId();
  const searchBarId = id ?? generateId;
  const isMedium = useMediaQuery("(min-width: {medium})");
  const length = inputProps?.value?.toString().length;

  return (
    <StyledSearchBarRoot>
      <Label sizeVariant="md" htmlFor={searchBarId}>
        {label}
      </Label>

      <StyledSearchBarContent>
        <TextField
          id={searchBarId}
          startIcon={<IconSearch className="icon" aria-hidden="true" />}
          endIcon={
            length
              ? length > 0 && (
                  <IconClose
                    className="icon icon-right-input"
                    onClick={cleanInput}
                    aria-label="Cerrar campo de búsqueda"
                  />
                )
              : undefined
          }
          {...inputProps}
        />
        {isMedium && (
          <Button
            sizeVariant="lg"
            color="secondary"
            startIcon={<IconSearch className="icon" aria-hidden="true" />}
            onClick={onClick}
            aria-label="Buscar"
          />
        )}
      </StyledSearchBarContent>
    </StyledSearchBarRoot>
  );
};
export default SearchBar;
