import Button, { type IButton } from "@components/Atoms/Button";
import IconCart from "@iconsV2/Interrface/cart-01.svg?react";
import Caption from "@/components/Atoms/Caption";
import { StyledCounter, StyledWrapperButtonOrder } from "./styles";
import { theme } from "twin.macro";

const OrderButton = ({ counter = 0, sizeVariant, ...props }: IButton & { counter: number }) => {
  return (
    <StyledWrapperButtonOrder $withShadow={false} $counterVisible={counter > 0}>
      <Button sizeVariant={sizeVariant} startIcon={<IconCart />} {...props} />

      {counter > 0 && (
        <StyledCounter>
          <Caption color={theme`colors.error.100`} fontWeight={700}>
            {counter > 99 ? "99" : counter}
          </Caption>
        </StyledCounter>
      )}
    </StyledWrapperButtonOrder>
  );
};

export default OrderButton;
