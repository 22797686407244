import tw, { styled } from "twin.macro";

export const StyledWrapperButtonOrder = styled.div<{
  $withShadow: boolean;
  $counterVisible: boolean;
}>`
  ${tw`relative`}

  ${({ $counterVisible }) => $counterVisible && tw`mr-2 large:(mr-0)`}
  & > button {
    ${tw`h-[1.875rem] w-[1.875rem] medium:(h-[2rem] w-[2rem]) large:(w-[2.25rem] h-[2.25rem])`}

    ${({ $withShadow }) => {
      if ($withShadow) return tw`shadow-elevation-2`;
    }}

    & svg {
      ${tw`w-[1.125rem] h-[1.125rem] medium:(w-[1.5rem] h-[1.5rem])`}
    }
  }
`;

export const StyledCounter = styled.span`
  ${tw`absolute  bg-error-50 border-2 border-error-100 rounded-round p-xxxs h-[1.125rem] w-[1.125rem] flex items-center justify-center -top-0 -right-3
    medium:(w-5 h-5)
    large:(p-xs w-6 h-6)
  `}
`;
