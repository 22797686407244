import tw, { styled } from "twin.macro";

export const StyledSearchBarRoot = styled.div`
  ${tw`flex flex-col gap-s medium:(gap-m)`}
`;
export const StyledSearchBarContent = styled.div`
  ${tw`relative flex gap-sm border transition-colors duration-300 rounded-[6.25rem] hover:(border-neutral-30 bg-neutral-99 )`}

  .icon {
    ${tw`cursor-pointer`}
  }
  .icon-left-input {
    ${tw`fill-neutral-50`}
  }

  Button {
    svg {
      ${tw`w-7 h-7 large:(w-9 h-9)`}
    }
  }
  div:nth-child(1) {
    ${tw`border-none bg-transparent px-m  py-s medium:(py-sm) large:(py-[13px]) `}
  }
`;
