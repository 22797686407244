import { request } from "../../utils/request";
import { supabaseClient } from "../../supabase/client";
import http from "@/utils/http";
import { API_URL_V2 } from "@/recourses/constants";

const BASE_API_URL = import.meta.env.REACT_APP_BASE_API_URL;
const ORDERS_ENDPOINT = "orders";
const ORDER_SOURCE_TYPE_ONLINE = "online";
const ORDER_STATUS_NEW = "created";
const ORDER_STATUS_RESERVED = "reserved";
const ENVIROMENT = import.meta.env.REACT_APP_ENVIRONMENT;
const PENDING_ORDERS = "/orders/?pending=true";

export const getOrdersV2 = async (args: Record<string, any>): Promise<OrderPaginated | undefined> => {
  try {
    const response = await http.get(`${BASE_API_URL}api/v1/${ORDERS_ENDPOINT}/`, { params: args });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getOrders = async (nextPage = null, filters = { limit: 5 }): Promise<OrderPaginated> => {
  const response = await request<OrderPaginated>(nextPage ?? `${BASE_API_URL}api/v1/${ORDERS_ENDPOINT}/`, {
    method: "GET",
    timeout: 30000,
    params: filters,
  });
  return response.data;
};

const getOrder = async (id: string | number, authToken: string | null = null): Promise<OrderResult> => {
  const response = await request<OrderResult>(`${BASE_API_URL}api/v1/${ORDERS_ENDPOINT}/${id}/`, {
    method: "GET",
    ...(authToken ? { headers: { Authorization: `Bearer ${authToken}` } } : {}),
  });
  return response.data;
};

const rateOrder = async ({
  id,
  npsScore,
  npsComment,
  authToken = null,
}: RateOrderRequestType): Promise<RateOrderResult> => {
  const response = await request<RateOrderResult>(`${BASE_API_URL}api/v1/${ORDERS_ENDPOINT}/${id}/rate/`, {
    method: "POST",
    data: {
      nps_score: npsScore,
      ...(npsComment ? { nps_comment: npsComment } : {}),
    },
    ...(authToken ? { headers: { Authorization: `Bearer ${authToken}` } } : {}),
  });
  return response.data;
};

const createOrder = async ({
  items,
  shipping_address,
  service,
  coupon,
  should_use_credit_available,
  should_receive_notifications,
  should_use_referral_credit_available,
  user_payment_method,
  store,
  scheduled_at,
  user_comment,
  meta_data,
  gift_from,
  gift_for,
  gift_comment,
  is_gift,
  basket_from,
  basket_for,
  basket_comment,
  is_basket,
  dni,
}: {
  items: Item[];
  shipping_address: number;
  service: number;
  should_use_credit_available: boolean;
  should_use_referral_credit_available: boolean;
  user_payment_method: number;
  store: number;
  coupon: number;
  scheduled_at: string;
  user_comment?: string;
  dni: string | number | readonly string[] | undefined;
  is_gift: boolean;
  gift_from: string | number | readonly string[] | undefined;
  gift_for: string | number | readonly string[] | undefined;
  gift_comment: string | number | readonly string[] | undefined;
  is_basket: boolean;
  basket_from: string | number | readonly string[] | undefined;
  basket_for: string | number | readonly string[] | undefined;
  basket_comment: string | number | readonly string[] | undefined;
  should_receive_notifications: boolean;
  meta_data: {
    user_agent: string;
    ip_address: string;
  };
}): Promise<OrderAttr> => {
  const response = await request<OrderAttr>(`${BASE_API_URL}api/v1/${ORDERS_ENDPOINT}/`, {
    method: "POST",
    data: {
      should_receive_notifications,
      items,
      shipping_address,
      service,
      coupon,
      should_use_credit_available,
      should_use_referral_credit_available,
      user_payment_method,
      store,
      scheduled_at,
      user_comment,
      meta_data,
      gift_from,
      gift_for,
      gift_comment,
      is_gift,
      basket_from,
      basket_for,
      basket_comment,
      is_basket,
      dni,
    },
    timeout: 50000,
  });

  return response.data;
};

const canCancelOrder = (orderData: OrderResult): boolean => {
  const currentTime = new Date();
  const cancellableStatuses = [ORDER_STATUS_NEW, ORDER_STATUS_RESERVED];

  if (
    // TODO: also check schedule type
    orderData.source === ORDER_SOURCE_TYPE_ONLINE &&
    orderData.scheduled_at &&
    cancellableStatuses.includes(orderData.status)
  ) {
    const maxCancellationTime = new Date(Date.parse(orderData.scheduled_at) - 1000 * 60 * 60 * 2);
    if (currentTime < maxCancellationTime) {
      return true;
    }
  }
  return false;
};

const cancelOrder = async ({
  orderId,
  reason,
}: {
  orderId: string;
  reason: string;
}): Promise<CancelOrderResponse> => {
  const response = await request<CancelOrderResponse>(
    `${BASE_API_URL}api/v1/${ORDERS_ENDPOINT}/${orderId}/cancel/`,
    {
      method: "POST",
      data: { reason },
      timeout: 28000,
    }
  );
  return response.data;
};

const getActiveOrdersByUserId = async ({ user_id }: OrderParams): Promise<any> => {
  const response = await supabaseClient
    .from<TypeOrder>(ENVIROMENT === "production" ? "order_prod" : "order_staging")
    .select("*")
    .eq("user_id", user_id)
    .or("status.eq.created,status.eq.prepared,status.eq.shipped");
  return response.data;
};

const retryPayment = async ({
  orderId,
  user_payment_method,
  meta_data,
}: RetryPaymentParams): Promise<OrderResult> => {
  const response = await request<OrderResult>(`${BASE_API_URL}api/v1/orders/${orderId}/retry-payment/`, {
    method: "POST",
    data: {
      user_payment_method,
      meta_data,
    },
  });

  return response.data;
};

const getListPedingOrders = async (): Promise<ApiResponsePendingOrders> => {
  return await http.get(`${API_URL_V2}${PENDING_ORDERS}`);
};
export {
  createOrder,
  getOrders,
  getOrder,
  canCancelOrder,
  cancelOrder,
  rateOrder,
  getActiveOrdersByUserId,
  retryPayment,
  getListPedingOrders,
};
