import { StyledContainerIconOrder, StyledNotification } from "./styles";
import IconBike from "@iconsV2/Interrface/bike.svg?react";

interface IconOrderRoundProps {
  isScrolled: boolean;
  isNotificationBadgeVisible?: boolean;
}
export const IconOrderRound = ({ isScrolled, isNotificationBadgeVisible }: IconOrderRoundProps) => {
  return (
    <StyledContainerIconOrder $isScrolled={isScrolled}>
      <IconBike />
      {isNotificationBadgeVisible && <StyledNotification $isScrolled={isScrolled} />}
    </StyledContainerIconOrder>
  );
};

export default IconOrderRound;
