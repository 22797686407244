import { useState, useEffect } from "react";
import Caption from "@/components/Atoms/Caption";
import { StyledBottomNotificationHeader } from "./styles";
import tw, { css, theme } from "twin.macro";
import { Link } from "react-router-dom";

interface Notification {
  text: string;
  bg: string;
  color?: string;
  to?: string;
}

interface BottomNotificationHeaderProps {
  duration?: number;
}

const notifications: Notification[] = [
  {
    text: "Horas locas hasta un 20% de descuento",
    bg: theme`colors.secondary.90`,
    to: "/horas-locas",
  },

  {
    text: "¡Envío gratis en compras mayores a $500!",
    bg: theme`colors.primaryV2.90`,
  },
];
const BottomNotificationHeader = ({ duration = 10000 }: BottomNotificationHeaderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (notifications.length <= 1) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    }, duration);

    return () => clearInterval(interval);
  }, [notifications, duration]);

  if (notifications.length === 0) {
    return null;
  }

  const currentNotification = notifications[currentIndex];

  return (
    <StyledBottomNotificationHeader
      $backgroundColor={currentNotification.bg}
      as={currentNotification.to ? Link : "div"}
      to={currentNotification.to}
      title={currentNotification.to ? "Ver más" : undefined}
    >
      <Caption
        fontWeight="600"
        color={currentNotification?.color ?? theme`colors.neutral.10`}
        css={css`
          ${currentNotification.to && tw`cursor-pointer hover:(underline underline-offset-1)`}
        `}
      >
        {currentNotification.text}
      </Caption>
    </StyledBottomNotificationHeader>
  );
};

export default BottomNotificationHeader;
