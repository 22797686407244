import tw, { css } from "twin.macro";

const styles = {
  maxWith: tw`max-w-screen-large`,
  wrapper: tw`px-m large:(mx-auto px-null!)`,
};

export const styledWrapperBase = css`
  ${styles.maxWith}
  ${styles.wrapper}
`;
