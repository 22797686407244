import { createClient } from "@supabase/supabase-js";
import { RealtimeClient } from "@supabase/realtime-js";

const supabaseUrl = import.meta.env.REACT_APP_SUPABASE_URL || "";
const supabaseKey = import.meta.env.REACT_APP_SUPABASE_API_KEY || "";
const supabaseUrlSocket = import.meta.env.REACT_APP_SUPABASE_URL_SOCKET || "";

export const supabaseClient = createClient(supabaseUrl, supabaseKey);
export const supabaseRealtimeclient = new RealtimeClient(supabaseUrlSocket, {
  params: { apikey: supabaseKey },
});
