import { StyledPrimaryButtonHeaderRoot } from "./styles";
import { type RuleSet } from "styled-components";
import { type TwStyle } from "twin.macro";
import ButtonText from "@/components/Atoms/ButtonText";
import React from "react";
import { Link } from "react-router-dom";

interface PrimaryButtonHeaderProps {
  classes?: {
    svg?: RuleSet<object> | TwStyle;
  };
  children?: React.ReactNode;
  icon: React.ReactNode;
  to?: string;
  onClick?: () => void;
  title?: string;
}

const PrimaryButtonHeader = ({ classes, icon, children, to, onClick, title }: PrimaryButtonHeaderProps) => {
  return (
    <StyledPrimaryButtonHeaderRoot
      as={to ? Link : "div"}
      to={to}
      onClick={onClick}
      title={title}
      $classess={classes?.svg}
    >
      {icon}
      <ButtonText sizeVariant="xss" className="text-primaryButtonHeader" tw="w-max" fontWeight="600">
        {children}
      </ButtonText>
    </StyledPrimaryButtonHeaderRoot>
  );
};

export default PrimaryButtonHeader;
