import { forwardRef, type HTMLProps } from "react";
import tw, { css } from "twin.macro";
import { useMergeRefs, FloatingPortal, FloatingOverlay } from "@floating-ui/react";

import Card, { type ICard } from "@atoms/Card";
import { useFloatingUIContext, FloatingUIProvider, type IFloatingUIProvider } from "@context/FloatingUI";
import { StyledWrapperArrowSvg, StyledWrapperTrigger } from "./styles";

export const PopoverTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const context = useFloatingUIContext();
    const ref = useMergeRefs([context.refs.setReference, propRef]);

    return (
      <StyledWrapperTrigger
        ref={ref}
        data-state={context.open ? "open" : "closed"}
        {...context.getReferenceProps(props)}
      >
        {children}
      </StyledWrapperTrigger>
    );
  }
);

export const PopoverContent = forwardRef<HTMLElement, HTMLProps<HTMLElement> & Pick<ICard, "classes">>(
  ({ style, children, classes, ...props }, propRef) => {
    const context = useFloatingUIContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.open) return null;
    return (
      <>
        {(context?.lockScroll || context?.fullScreen) && (
          <FloatingOverlay lockScroll={context?.lockScroll || context?.fullScreen} />
        )}

        <FloatingPortal>
          <Card
            ref={ref}
            style={{
              ...context.floatingStyles,
              width: context.withReferenceWidth
                ? context?.refs?.reference?.current?.getBoundingClientRect()?.width
                : context.floatingStyles?.width,
              ...style,
              visibility: context.middlewareData.hide?.referenceHidden ? "hidden" : "visible",
              display: context.middlewareData.hide?.referenceHidden ? "none" : "flex",
            }}
            {...context.getFloatingProps(props)}
            classes={{
              root: css`
                ${tw`shadow-elevation-3 z-50 bg-neutral-100`}
                ${context?.fullScreen
                  ? tw`!(fixed inset-0 transform translate-x-0 w-full h-[100dvh] p-0 flex flex-col z-[52] overflow-auto rounded-none)`
                  : tw``}
                ${classes?.root ? classes.root : tw``}
              `,
            }}
          >
            {children}
            {context.arrow && <StyledWrapperArrowSvg ref={context.arrowRef} context={context.context} />}
          </Card>
        </FloatingPortal>
      </>
    );
  }
);

const Popover = ({ children, ...options }: Omit<IFloatingUIProvider, "role">) => {
  if (options.arrow === undefined) {
    options.arrow = false;
  }
  return (
    <FloatingUIProvider {...options} role="tooltip">
      {children}
    </FloatingUIProvider>
  );
};

export default Popover;
