import tw, { css, styled, type TwStyle } from "twin.macro";
import Card from "../Card";
import { type CSSProperties } from "react";
import { type RuleSet } from "styled-components";

export const StyledCard = styled(Card)<{
  $backgroundColor?: CSSProperties["backgroundColor"];
}>`
  ${tw`bg-secondary-100 p-0`}
  ${({ $backgroundColor }) => {
    if ($backgroundColor) {
      return css`
        background: ${$backgroundColor};
      `;
    }
  }}
`;

export const StyledSummary = styled.summary`
  ${tw`w-full flex list-none cursor-pointer`}

  &::-webkit-details-marker {
    display: none;
  }
`;

export const StyledHeading = styled.div<{
  $classes?: RuleSet<object> | TwStyle;
  $headingWithBorder: boolean;
}>`
  ${tw`w-full flex items-center justify-between px-m py-[0.875rem]`}
  ${({ $headingWithBorder }) => $headingWithBorder && tw`border-b border-b-neutral-90`}
  ${({ $classes }) => $classes && $classes}
`;

export const StyledContentClose = styled.div`
  ${tw`w-full flex flex-col px-m py-s border-t border-t-neutral-90`}
`;

export const StyledDetails = styled.details<{ $maxHeight: number }>`
  ${tw`w-full overflow-hidden`}

  &[open] + div#card-dismissable-content {
    max-height: ${({ $maxHeight }) => $maxHeight}px;
  }
`;

export const StyledContent = styled.div`
  ${tw`w-full max-h-0 box-border overflow-hidden`}
  transition: max-height 300ms ease-out;
`;

export const StyledContentPadding = styled.div`
  ${tw`w-full h-full p-m`}
`;
