import CategoryButton from "@/components/Atoms/CategoryButton";
import tw, { styled } from "twin.macro";

const styles = {
  menuSectionContainer: {
    root: tw`flex flex-col gap-s`,
    item: tw`transition-all duration-300 py-[0.625rem] rounded-s (medium:(px-0 py-[0.688rem] large:(py-[0.75rem])) hover:(bg-transparent text-secondary-60 underline))!`,
    itemPromo: tw`text-primaryV2-50 hover:(text-primaryV2-50)!`,
    svg: tw`w-6 h-6 medium:(w-7 h-7) large:(w-8 h-8)`,
  },
};
export const StyledMenuLinks = styled(CategoryButton)``;

export const StyledMenuSectionContainer = styled.section`
  ${styles.menuSectionContainer.root}

  .header-menu-item {
    ${styles.menuSectionContainer.item}

    svg {
      ${styles.menuSectionContainer.svg}
    }
  }

  .header-menu-item__promo {
    ${styles.menuSectionContainer.itemPromo}
  }
`;
