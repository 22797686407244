import { type RefObject, useEffect } from "react";

const useResizeObserver = ({
  ref,
  callback,
}: {
  ref?: RefObject<HTMLDivElement>;
  callback: (entry: ResizeObserverEntry) => void;
}) => {
  useEffect(() => {
    if (ref?.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        callback(entries?.[0]);
      });

      resizeObserver.observe(ref.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [ref?.current]);
};

export default useResizeObserver;
