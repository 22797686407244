import { Suspense, useEffect, useState } from "react";
import type { TOrientation } from "@/types/common";
import IconOtra from "@iconsV2/Illustration/otra.svg?react";
import useAppSelector from "@/hooks/useAppSelector";
import InfoPopup from "@/components/Molecules/InfoPopup";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import Caption from "../Caption";
import { StyledWrapperCiity, StyledWrapperTextVertical } from "./styles";
import { getCorrectCityName } from "@/utils/CorrectCityNames";
import ModalAddressForm from "@/components/Organisms/ModalAddressForm";
import { filterCities } from "@/utils/CountriesConfig";
import { COUNTRYCODE } from "@/recourses/constants";
import citiesAddress from "@/utils/citiesAddress";
import { textNormalize } from "@/utils/textNormalize";
import ButtonText from "@/components/Atoms/ButtonText";

export interface CiityProps {
  open: boolean;
  handleChangeOpen: (open: boolean) => void;
  withPopover?: boolean;
  textDirection?: TOrientation;
  fullWidth?: boolean;
  removeBoxStyle?: boolean;
  [key: string]: any;
}

const listStores = filterCities(COUNTRYCODE as CountryCode, true);

const storeAssociatedAddresses = citiesAddress?.filter((item) =>
  listStores?.some((store) => store.name?.toLowerCase()?.includes(item.city?.toLowerCase()))
);

const Ciity = ({
  open,
  handleChangeOpen,
  withPopover = true,
  textDirection = "vertical",
  removeBoxStyle = false,
  fullWidth,
  ...props
}: CiityProps) => {
  const isMedium = useMediaQuery("(min-width: {medium})");
  const location = useAppSelector((state) => state.cart.location);
  const showAddressReminder = useAppSelector((state) => state.popUpsReminder.showAddressReminder);

  const [showPopUps, setshowPopUps] = useState(false);

  const closePopUps = () => {
    if (!showPopUps) return;
    setshowPopUps(false);
  };

  useEffect(() => {
    if (location?.test && showAddressReminder) {
      setshowPopUps(true);
    } else {
      if (showPopUps) {
        setshowPopUps(false);
      }
    }
  }, [location?.test, showAddressReminder]);

  const findAssociatedStoreAddress = () => {
    if (!Array.isArray(storeAssociatedAddresses)) return "";

    const currentStore = location.city
      ? storeAssociatedAddresses.find((item) => {
          return textNormalize(item.city || "") === textNormalize(location.city);
        })
      : storeAssociatedAddresses[0];

    return currentStore?.address ?? "jajja";
  };
  const renderAddress = () => {
    const storeAddress = findAssociatedStoreAddress();

    // Validate location and its properties before using them
    const locationCity = location?.city ? textNormalize(location.city) : "";
    const locationAddress = location?.address?.split(",")[0] || "";

    // example address is Cra. 70c #72a99, Bogotá, Colombia  convert to ---> Cra. 70c #72a99
    if (locationCity !== textNormalize(locationAddress.split(" ")[0] || "")) {
      // If the city in the location does not match the start of the address, return the first part of the address
      return locationAddress;
    }

    // If the address matches the city, return the store's associated address
    return storeAddress;
  };

  return (
    <>
      <Suspense fallback={""}>
        {open && <ModalAddressForm isOpen={open} toggleAddressModal={handleChangeOpen} />}
      </Suspense>

      <InfoPopup
        minWidth={isMedium ? "14.9rem" : "12.3413rem"}
        title="¿Te encuentras aquí?"
        description={
          isMedium ? "Elige tu ubicación actual para mostrarte los productos disponibles." : undefined
        }
        popoverProps={{
          open: showPopUps && withPopover,
          withReferenceWidth: true,
          arrow: true,
          placement: "bottom-start",
        }}
        onClick={closePopUps}
      >
        <StyledWrapperCiity
          onClick={() => {
            handleChangeOpen(true);
            closePopUps();
          }}
          $orientation={textDirection}
          $fullWidth={fullWidth}
          $removeBoxStyle={removeBoxStyle}
          {...props}
        >
          <IconOtra />

          {textDirection === "vertical" && (
            <StyledWrapperTextVertical>
              <Caption size="small" tw="capitalize" className="city-text">
                {getCorrectCityName(location?.city ? location?.city : location?.address)}
              </Caption>
              <Caption
                size="small"
                fontWeight={600}
                className="city-text"
                tw="max-w-[5.313rem] line-clamp-1 text-start"
              >
                {renderAddress()}
              </Caption>
            </StyledWrapperTextVertical>
          )}

          {textDirection === "horizontal" && (
            <>
              <ButtonText sizeVariant="lg" tw="max-w-[17.375rem] line-clamp-1">
                {getCorrectCityName(location?.city ? location?.city : location?.address)} {renderAddress()}
              </ButtonText>
            </>
          )}
        </StyledWrapperCiity>
      </InfoPopup>
    </>
  );
};

export default Ciity;
