import type { ProductHit } from "@/recourses/meilisearch/meiliSearch";
import { MIN_AVAILABLE_QUANTITY } from "@/recourses/constants";
import type { IProductGalleryData } from "@/types/product";
import type { RecombeeValuesItemUser } from "@/types/recombee";
import { calculatePercentageDiscount } from "./money";

export const promofitOptions = ["expiration_discount", "normal"];

export const baseProduct = {
  name: "Harina de Garbanzo - fithub x 250 g",
  brand: "Fithub",
  price: 5500,
  originalPrice: 5500,
  isNew: false,
  availabilityOnSaleOnline: true,
  imagen:
    "https://dexter-media-prod-co.s3.amazonaws.com/__sized__/products/79077149-crop-c0-5__0-5-255x255-90.jpg",
  brandSlug: "fithub",
  id: 1,
  quatityAvailable: 12,
  sku: "123456",
  slug: "harina-de-garbanzo-fithub-x-250-g",
  storeId: 1,
  storeType: "supermarket",
  isPromoted: false,
  bestSeller: false,
};

export const arrayProducts = Array.from({ length: 16 }).map((_) => baseProduct);

export const hitToProductGallery = (hit: ProductHit | undefined): IProductGalleryData | undefined => {
  if (!hit) {
    return undefined;
  }

  const pricePerStepQty = hit.price_online_gross * (hit.step_qty ?? 1);
  const originalPricePerStepQty = hit.price_online_undiscounted_gross * (hit.step_qty ?? 1);

  return {
    id: hit.id,
    brand: hit.product_brand,
    name: hit.product_name,
    originalPrice: hit.price_online_undiscounted_gross,
    price: hit.price_online_gross,
    pricePerStepQty,
    originalPricePerStepQty,
    perMeasureUnitDisplay: hit.price_per_measure_unit_display,
    imagen: hit.product_thumbnail_url,
    storeId: hit.store_id,
    quatityAvailable: hit.product_availability_max_qty_to_order,
    isAvailable: hit?.product_availability_available_online,
    availabilityOnSaleOnline: hit.product_availability_on_sale_online,
    brandSlug: hit.product_brand_slug,
    slug: hit.product_slug,
    unit: 0,
    isNew: hit.is_new,
    isPromoted: hit.is_promoted === 1,
    customLabel: hit.custom_label,
    customLabelBackgroundColor: hit.custom_label_background_color,
    description: hit.product_description,
    sku: hit.product_sku ?? "",
    storeType: hit.store_type ?? "",
    stepQty: hit.step_qty ?? 1,
    measureTypeShort: hit.product_measure_type_display_short ?? "",
  };
};

export const graphqlToProductGallery = (item: Record<string, any>): IProductGalleryData => {
  const pricePerStepQty = item.availability.price.gross * (item.stepQty ?? 1);
  const originalPricePerStepQty = item.availability.priceUndiscounted.gross * (item.stepQty ?? 1);

  return {
    id: item.pk,
    brand: item.details.brand.name,
    brandSlug: item.details.brand.slug,
    imagen: item.details.thumbnail.url,
    name: item.details.name,
    slug: item.details.slug,
    pricePerStepQty,
    originalPricePerStepQty,
    originalPrice: item.availability.priceUndiscounted.gross,
    price: item.availability.price.gross,
    perMeasureUnitDisplay: item.availability.pricePerMeasureUnitDisplay,
    availabilityOnSaleOnline: item.availability.onSale,
    quatityAvailable: item.availability.maxQtyToOrder,
    storeId: Number(item?.store?.pk ?? 0),
    unit: 0,
    isNew: item.availability.isNew,
    isPromoted: Boolean(item.isFeatured),
    customLabel: item.customLabel ?? "",
    customLabelBackgroundColor: item.customLabelBackgroundColor ?? "",
    description: item.details.description ?? "",
    sku: item.details.sku ?? "",
    storeType: item.details.storeType ?? "",
    isAvailable: item.availability.available,
    stepQty: item.stepQty ?? 1,
    measureTypeShort: item.details.measureTypeDisplayShort ?? "",
  };
};

export const checkProductHasStock = (item: Cart) => {
  const productFormatted = graphqlToProductGallery(item);

  return (
    productFormatted.quatityAvailable > MIN_AVAILABLE_QUANTITY &&
    productFormatted.isAvailable &&
    item.quantity <= item.availability.maxQtyToOrder
  );
};

export const recombeeToProductGallery = (id: number, item: RecombeeValuesItemUser): IProductGalleryData => {
  const pricePerStepQty = item.price_online_gross * (item.step_qty ?? 1);
  const originalPricePerStepQty = item.price_online_undiscounted_gross * (item.step_qty ?? 1);

  return {
    id,
    brand: item.product_brand,
    brandSlug: item.product_brand_slug,
    imagen: item.product_thumbnail_url,
    name: item.product_name,
    slug: item.product_slug,
    pricePerStepQty,
    originalPricePerStepQty,
    originalPrice: item.price_online_undiscounted_gross,
    price: item.price_online_gross,
    perMeasureUnitDisplay: item.price_per_measure_unit_display,
    availabilityOnSaleOnline: item.product_availability_on_sale_online,
    quatityAvailable: item.product_availability_max_qty_to_order,
    storeId: Number(item.store_id),
    unit: 0,
    isNew: item.is_new,
    isPromoted: item.is_promoted === 1,
    customLabel: item.custom_label ?? "",
    customLabelBackgroundColor: item.custom_label_background_color ?? "",
    description: item.product_description ?? "",
    sku: String(item.product_sku ?? ""),
    storeType: item.store_type ?? "",
    isAvailable: item.product_availability_available_online,
    stepQty: item.step_qty ?? 1,
    measureTypeShort: item.product_measure_type_display_short ?? "",
  };
};

export const recombeeToOldCart = (id: number, item: RecombeeValuesItemUser | ProductHit): Cart => {
  const pricePerStepQty = item.price_online_gross * (item.step_qty ?? 1);
  const originalPricePerStepQty = item.price_online_undiscounted_gross * (item.step_qty ?? 1);

  return {
    pk: String(id),
    quantity: 1,
    customLabel: item.custom_label ?? "",
    customLabelBackgroundColor: item.custom_label_background_color,
    stepQty: item.step_qty ?? 1,
    availability: {
      onSale: item.product_availability_on_sale_online,
      available: item.product_availability_available_online,
      maxQtyToOrder: item.product_availability_max_qty_to_order,
      price: {
        gross: item.price_online_gross,
        currency: item.product_availability_currency,
      },
      discount: {
        gross: item.product_availability_discount_online,
        currency: item.product_availability_currency,
      },
      priceUndiscounted: {
        gross: item.price_online_undiscounted_gross,
        currency: item.product_availability_currency,
      },
      priceMembership: {
        gross: item.price_online_membership_gross,
        currency: item.product_availability_currency,
      },
      pricePerStepQty: {
        gross: pricePerStepQty,
        currency: item.product_availability_currency,
      },
      priceUndiscountedPerStepQty: {
        gross: originalPricePerStepQty,
        currency: item.product_availability_currency,
      },
    },
    details: {
      name: item.product_name,
      slug: item.product_slug,
      avgRating: item.product_avg_rating,
      sku: +item.product_sku,
      containsAlcohol: item.product_contains_alcohol,
      brand: {
        name: item.product_brand,
        slug: item.product_brand_slug,
      },
      thumbnail: {
        url: item.product_thumbnail_url,
      },
      measureTypeDisplayShort: item.product_measure_type_display_short,
    },
  };
};

export const getPercentageDiscount = ({
  unitPriceDiscountedGross,
  discountType,
  unitPriceGross,
}: {
  unitPriceDiscountedGross?: string | null;
  discountType?: string | null;
  unitPriceGross?: string | null;
}) => {
  let percentage = 0;

  /* Calculate percentage */
  if (unitPriceDiscountedGross && promofitOptions.includes(discountType ?? "")) {
    percentage = calculatePercentageDiscount({
      priceOriginal: parseFloat(unitPriceGross ?? "0"),
      priceDiscount: parseFloat(unitPriceDiscountedGross ?? "0"),
    });
  }

  return percentage;
};

export const oldCartToNewCart = (storeId: number, item: Cart): NewCart => {
  return {
    storeId: String(storeId),
    quantity: item.quantity,
    product: {
      id: String(item.pk),
      name: item.details.name,
      slug: item.details.slug,
      sku: item.details.sku,
      brand: {
        name: item.details.brand.name,
        slug: item.details.brand.slug,
      },
      image: {
        alt: item.details.thumbnail?.alt ?? item.details.name,
        url: item.details.thumbnail.url,
      },
      avgRating: item.details?.avgRating,
      containsAlcohol: item.details?.containsAlcohol ?? false,
    },
    detail: {
      onSale: item.availability.onSale,
      available: item.availability.available,
      maxQtyToOrder: item.availability.maxQtyToOrder,
      customLabel: item?.customLabel ?? "",
      customLabelBackgroundColor: item?.customLabelBackgroundColor,
      stepQty: item.stepQty,
    },
    price: {
      currency: item?.availability?.price?.currency,
      finalPrice: item?.availability?.price?.gross ?? 0,
      originalPrice: item?.availability?.priceUndiscounted?.gross ?? 0,
      membership: item.availability.priceMembership?.gross ?? 0,
      discount: item.availability?.discount?.gross ?? item.availability?.discount ?? 0,
      finalPricePerStepQty: item.availability.pricePerStepQty?.gross ?? 0,
      originalPricePerStepQty: item.availability.priceUndiscountedPerStepQty?.gross ?? 0,
    },
  };
};

export const newCartToOldCart = (item: NewCart): Cart => {
  return {
    pk: String(item.product.id),
    quantity: item.quantity,
    customLabel: item.detail.customLabel,
    customLabelBackgroundColor: item.detail.customLabelBackgroundColor,
    availability: {
      onSale: item.detail.onSale,
      available: item.detail.available,
      maxQtyToOrder: item.detail.maxQtyToOrder,
      price: {
        gross: item.price.finalPrice,
        currency: item.price.currency,
      },
      discount: {
        gross: item.price.discount,
        currency: item.price.currency,
      },
      priceUndiscounted: {
        gross: item.price.originalPrice,
        currency: item.price.currency,
      },
      priceMembership: {
        gross: item.price.membership,
        currency: item.price.currency,
      },
      pricePerStepQty: {
        gross: item.price.originalPricePerStepQty,
        currency: item.price.currency,
      },
      priceUndiscountedPerStepQty: {
        currency: item.price.finalPricePerStepQty,
        gross: item.price.originalPrice,
      },
    },
    details: {
      name: item.product.name,
      slug: item.product.slug,
      avgRating: item.product?.avgRating,
      sku: +item.product.sku,
      containsAlcohol: item.product?.containsAlcohol ?? false,
      brand: {
        name: item.product.brand.name,
        slug: item.product.brand.slug,
      },
      thumbnail: {
        url: item.product.image.url,
      },
    },
    stepQty: item.detail.stepQty,
  };
};
